import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  ${({ theme, ...props }) => {
    return css`
      html {
        scroll-behavior: smooth;
        font-size: 14px;

        @media screen and (min-width: ${theme.breakpoint.xl}) {
          font-size: 14px;
        }

        // TODO: REVIEW THESE BREAKPOINTS
        @media screen and (min-width: 1441px) {
          font-size: 16px;
        }

        // TODO: REVIEW THESE BREAKPOINTS MAYBE REMOVE THIS ONE
        //@media screen and (min-width: 1441px) {
        //font-size: 16px;
        //}

        // TODO: CREATE A NEW TOKEN breakpoint_max or xxl2
        //@media screen and (min-width: 1800px) {
        //font-size: 18px;
        //}

        // TODO: CREATE A NEW TOKEN breakpoint_max or xxl2
        @media screen and (min-width: 1921px) {
          //font-size: 18px;
        }
      }

      html,
      body {
        padding: 0;
        margin: 0;
        font-family: var(--font-family-text);
        color: ${theme.color.dark};
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: var(--font-family-title);
      }

      q,
      blockquote {
        font-family: var(--font-family-mono);
      }

      a {
        color: ${theme.color.secondary_500};
        text-decoration: none;
        &:hover {
          color: ${theme.color.secondary_600};
        }
      }

      // Dont show on mobile
      .hide-sm {
        @media screen and (max-width: ${theme.breakpoint.sm}) {
          display: none !important;
          visibility: hidden !important;
        }
      }
    `;
  }};
`;

export default GlobalStyle;
