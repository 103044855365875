// Do not edit directly
// Generated on Tue, 06 Jun 2023 14:29:46 GMT
//
// To update this file make sure to have
// the latest Tokens exported from Figma then run:
//
// $ yarn token
import { DefaultTheme } from 'styled-components';
const theme: DefaultTheme = {
  color: {
    white: '#fff',
    dark: '#000',
    brand_main: '#B1916A', // #B1916A
    primary_100: '#F7F3EF', // #F7F3EF
    primary_200: '#FEF1E0', // #FEF1E0
    primary_400: '#FDDBB1', // #FDDBB1
    primary_500: '#B1916A', // #B1916A
    primary_600: '#644E32', // #644E32
    primary_800: '#3C2F1E', // #3C2F1E
    secondary_100: '#F0F5F7', // #F0F5F7
    secondary_200: '#CAE0EA', // #CAE0EA
    secondary_400: '#6A9AB1', // #6A9AB1
    secondary_500: '#285164', // #285164
    secondary_600: '#2A3E47', // #2A3E47
    secondary_800: '#202E35', // #202E35
    neutral_100: '#F5F5F5', // #F5F5F5
    neutral_200: '#EBEBEB', // #EBEBEB
    neutral_400: '#C3C3C3', // #C3C3C3
    neutral_500: '#9B9B9B', // #9B9B9B
    neutral_600: '#666666', // #666666
    neutral_800: '#4A4A4A', // #4A4A4A
    destructive_100: '#FFA3A3', // #FFA3A3
    destructive_200: '#FF8585', // #FF8585
    destructive_400: '#FF6666', // #FF6666
    destructive_500: '#D85252', // #D85252
    destructive_600: '#B13D3D', // #B13D3D
    destructive_800: '#8B2929', // #8B2929
    success_100: '#C0F7D7', // #C0F7D7
    success_200: '#9DDFB9', // #9DDFB9
    success_400: '#7AC79A', // #7AC79A
    success_500: '#107F3F', // #107F3F
    success_600: '#0D6632', // #0D6632
    success_800: '#0A4C26', // #0A4C26
    warning_100: '#F8EAD6', // #F8EAD6
    warning_200: '#F1D5AE', // #F1D5AE
    warning_400: '#E9C085', // #E9C085
    warning_500: '#DB9634', // #DB9634
    warning_600: '#835A1F', // #835A1F
    warning_800: '#583C15', // #583C15
    info_100: '#D6EAF8', // #D6EAF8
    info_200: '#AED6F1', // #AED6F1
    info_400: '#85C1E9', // #85C1E9
    info_500: '#3498DB', // #3498DB
    info_600: '#2A7AAF', // #2A7AAF
    info_800: '#153D58', // #153D58
  },
  typography: {
    font_size: {
      text_xs: '0.625rem', // 10px
      text_sm: '0.875rem', // 14px
      text_base: '1rem', // 16px
      text_md: '1.25rem', // 20px
      text_lg: '1.5rem', // 24px
      text_xl: '2rem', // 32px
      title_base: '1rem', // 16px
      title_md: '1.25rem', // 20px
      title_lg: '1.5rem', // 24px
      title_xl: '2rem', // 32px
      title_2xl: '2.5rem', // 40px
      title_3xl: '3rem', // 48px
      title_4xl: '4rem', // 64px
      base: '16px', // The base font-size
    },
    font_family: {
      text: 'var(--font-family-text)', // The font family for body copy
      title: 'var(--font-family-title)', // The font family for headings
      mono: 'var(--font-family-mono)', // The font family for mono text
    },
    font_weight: {
      text_regular: '400',
      text_light: '300',
      text_bold: '700',
      title_light: '300', // 300
      title_semi_bold: '600', // 600
      title_black: '900', // 900
      mono_regular: '400',
      mono_bold: '700',
    },
    text_case: {
      uppercase: 'uppercase',
      lowercase: 'lowercase',
      capitalize: 'capitalize',
      none: 'none',
    },
    text_decoration: {
      none: 'none',
      underline: 'underline',
      line_through: 'line-through',
    },
    line_height: {
      120: '120%',
      140: '140%',
      150: '150%',
    },
  },
  spacing: {
    xxs: '0.125rem', // 2px
    xs: '0.25rem', // 4px
    sm: '0.5rem', // 8px
    base: '1rem', // 16px
    md: '2rem', // 32
    lg: '4rem', // 64px
    xl: '5rem', // 80px
    xxl: '6rem', // 96px
  },
  radius: {
    xxs: '0.125rem', // 2px
    xs: '0.275rem', // 4.4px
    sm: '0.5rem', // 8px
    base: '1rem', // 16px
    md: '1.5rem', // 24px
    lg: '2rem', // 32px
    xl: '3rem', // 48px
    full: '100px', // 100px
  },
  border: {
    base_neutral_200: '1px solid #EBEBEB', // Gray light border
    base_neutral_400: '1px solid #C3C3C3', // Gray border
    md_neutral_400: '2px solid #C3C3C3', // Gray border
  },
  border_width: {
    base: '1px', // 1px
    md: '2px', // 2px
    lg: '3px', // 3px
  },
  box_shadow: {
    sm: '0 5px 8px -8px rgba(0,0,0,.2)',
    base: '0 7px 16px 0 rgba(195, 195, 195, .3)',
    default: '0 0 4px 0 rgba(74, 74, 74, .3)',
  },
  container: {
    sm: '436px', // Small 436px = (4columns)
    md: '672px', // Medium 672px (6columns)
    lg: '1140px', // Large 1140px
    xl: '1376px', // Extra Large 1376px
  },
  breakpoint: {
    sm: '576px', // Small 576px
    md: '768px', // Medium 768px
    lg: '992px', // Large 992px
    xl: '1204px', // Extra large 1204px
    xxl: '1440px', // Extra extra large 1440px
  },
  transition: {
    border_color: 'border-color 0.15s ease-in-out', // border-color 0.15s ease-in-out
    color: 'background-color 0.15s ease-in-out', // background-color 0.15s ease-in-out
    all: 'all 0.15s ease-in-out', // all 0.15s ease-in-out
  },
  opacity: {
    60: '0.6', // default for overlays
  },
}

export default theme;
