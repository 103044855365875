export const APP_URL = process.env.APP_URL;
export const APP_URL_LEGACY = process.env.APP_URL_LEGACY;
export const APP_ACCOUNT_URL = process.env.APP_ACCOUNT_URL;

/**
 * NOTE: DON'T USE THIS FUNCTION FOR CLIENT-SIDE URL FORMATTING
 * */
export function url(pathname: string) {
  if (typeof window !== 'undefined') throw new Error('url() method should not be called within browser.');
  let path = pathname.replace(/^\//g, '').trim();
  return `${APP_URL}/${path}`;
}

export function url_legacy(pathname: string) {
  if (typeof window !== 'undefined') throw new Error('url() method should not be called within browser.');
  let path = pathname.replace(/^\//g, '').trim();
  return `${APP_URL_LEGACY}/${path}`;
}

export function url_account(pathname: string) {
  if (typeof window !== 'undefined') throw new Error('url() method should not be called within browser.');
  let path = pathname.replace(/^\//g, '').trim();
  return `${APP_ACCOUNT_URL}/${path}`;
}

// TODO: MOVE TO BROWSER.TS
export function urlCategory(categorySlug: string, contentSlug: string) {
  if (!contentSlug || !categorySlug) {
    throw new Error('categoryUrl cannot have null values for categorySlug and contentSlug');
  }
  return `/${categorySlug}/${contentSlug}.html`;
}

// TODO: MOVE TO BROWSER.TS
export function urlPacotes(slug?: string, travelType?: string, absolute?: string) {
  if (!slug) {
    return '/pacotes';
  }

  if (travelType) {
    return `/pacotes/${slug}?travelType=${travelType}`;
  }

  return `/pacotes/${slug}`;
}

// TODO: MOVE TO BROWSER.TS
export function urlViagens(slug?: string) {
  if (!slug) {
    return '/viagens';
  }
  slug = slug.replace(/^\//, '');
  return `/viagens/${slug}`;
}

export const paths = {
  csrf_token: '/proxy/form/token',
  form_create_journey: '/viagens/crie-seu-roteiro',
};
