import '@/styles/normalize.css';
import '@/styles/globals.css';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '@/styles/globalstyles';
import theme from '@/styles/theme';
import { Oxygen, Playfair_Display, Raleway } from 'next/font/google';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { paths } from '@/config/url';
import { RD_STATION_TOKEN } from '@/config';
import { start } from '@/lib/bugsnag';

start();
const oxygen = Oxygen({
  weight: ['300', '400', '700'],
  subsets: ['latin'],
  variable: '--font-family-text',
});

const raleway = Raleway({
  weight: ['300', '600', '900'],
  subsets: ['latin'],
  variable: '--font-family-title',
});

const playfair = Playfair_Display({
  weight: ['400'],
  subsets: ['latin'],
  variable: '--font-family-mono',
});

async function fetchCsrfToken(): Promise<{ csrf_token: string } | undefined> {
  try {
    const response = await fetch(paths.csrf_token);
    if (response.ok) {
      return { csrf_token: response.headers.get('X-TOKEN') || '' };
    }
  } catch (e) {
    throw e;
  }
}

export default function App({ Component, pageProps }: AppProps) {
  const [token, setToken] = useState(pageProps?.data?.meta?.csrf_token || '');

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string, { shallow }: any) => {
      // console.log(`App is changing to ${url} ${shallow ? 'with' : 'without'} shallow routing`);
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
    if (csrfTokenElement?.getAttribute('content')) {
      return;
    }

    if (!token) {
      fetchCsrfToken()
        .then((result) => {
          const { csrf_token } = result || {};
          if (csrf_token) {
            setToken(() => csrf_token);
          }
        })
        .catch((err) => {
          //
        });
    }
  });

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="csrf-token" content={token} />
        <meta name="rds-token" content={RD_STATION_TOKEN} />
        <link rel="icon" href="/favicon.png" />
      </Head>
      {/* prettier-ignore */}
      <style jsx global>{`
        :root {
          --font-family-text: ${oxygen.style.fontFamily}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
          --font-family-title: ${raleway.style.fontFamily}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
          --font-family-mono: ${playfair.style.fontFamily}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        }
      `}</style>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
}
